import request from '@http';
// 2023/05/22 王珂 申请人员列表
export function addUser(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/unlock/request/list`,
        params
    });
}
// 2023/05/22 王珂 下发门锁权限
export function sendPermission(data) {
    return request({
        method: 'POST',
        url: `/api/gzf/dc/unlock/request/save`,
        data
    });
}
// 2023/05/22 王珂 获取申请房间号
export function issueRoomList(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/unlock/request/room/list`,
        params
    });
}
// 2023/05/22 王珂 修改门锁权限
export function sendPermissionlist(data) {
    return request({
        method: 'POST',
        url: `/api/gzf/dc/unlock/request/authorize`,
        data
    });
}
// 2023/05/23 王珂 新增下发权限获取房间号
export function newRoomlist(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/unlock/request/save/room/list`,
        params
    });
}
// 2023/05/23 王珂 输入手机号自动回显
export function phoneNumber(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/unlock/request/user/phone`,
        params
    });
}
// 2023/05/23 王珂 关闭权限
export function ShutDown(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/unlock/request/close`,
        params
    });
}




