import {timeFormat} from "@custom";
export const tableColumn = [
    {
        type: "index",
        label: "编号",
        width: 100,
    },
    {
        prop: "applicantName",
        label: "申请人",
  },
  // {
  //   prop: "applicantName",
  //   label: "门锁时间",
  // },
    {
        prop: "type",
        label: "申请人类型",
    },
    {
        prop: "phone",
        label: "手机",
    },
    {
        prop: "requestType",
        label: "申请类型",

        render: (h, {row}) => {
            const {requestType} = row;
            //0-删除密码  1-开锁  
            let typeName = "";
            switch (requestType) {
                case 0:
                    typeName = "开锁";
                    break;
                case 1:
                    typeName = "删除密码";
                    break;
            }
            return h("span", (typeName));
        }
    },
    {
        prop: "startTime",
        label: "生效时间",
        render: (h, {row}) => {
            const {startTime} = row;
            return startTime ? timeFormat(new Date(startTime)) : "";
        }

    },
    {
        prop: "endTime",
        label: "失效时间",
        render: (h, {row}) => {
            const {endTime} = row;
            return endTime ? timeFormat(new Date(endTime)) : "";
        }
    },
    {
        prop: "reason",
        label: "申请原因",
    },
    {
        prop: "status",
        label: "申请状态",

        render: (h, {row}) => {
            const {status} = row;
            //0-申请中  1-开放中   2-待生效  3-申请结束  4-已关闭
            let codeName = "";
            switch (status) {
                case 0:
                    codeName = "申请中";
                    break;
                case 1:
                    codeName = "开放中";
                    break;
                case 2:
                    codeName = "待生效";
                    break;
                case 3:
                    codeName = "申请结束";
                    break;
                case 4:
                    codeName = "已关闭";
                    break;
            }
            return h("span", (codeName));
        }
    },
];
